import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Error 404 - Page Not Found" />
    <h1>ERROR 404 - PAGE NOT FOUND</h1>
    <p>Sorry, this page could not be found.</p>
  </Layout>
)

export default NotFoundPage
